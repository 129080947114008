import PropTypes from "prop-types";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Route } from "react-router-dom";

import LoadingOverlay from "components/Common/LoadingOverlay";
import ChunkLoadErrorBoundary from "components/Errors/ChunkLoadErrorBoundary";
import ErrorBoundary from "components/Errors/ErrorBoundary";
import Footer from "components/Layout/FooterAsync";
import HeaderNavWrapper from "components/Layout/Header/HeaderNavWrapper";
import RequestContext from "pages/RequestContext";

import useAddModules from "hooks/useAddModules";
import { useStyles } from "hooks/useStyles";

import { layoutStyles } from "styles/LayoutStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  ...layoutStyles,
  top__wrapper: {
    [ScreenSizes.lgAndAbove]: {
      display: "grid",
      gridTemplateRows: "max-content 1fr",
      overflow: "auto",
      height: "100%",
    },
  },
  topWrapperBody: {
    [ScreenSizes.lgAndAbove]: {
      height: "100%",
      overflow: "auto",
    },
  },
  topWrapperHeader: {
    [ScreenSizes.lgAndAbove]: {
      position: "fixed",
      top: 0,
      zIndex: 100,
      width: "100%",
    },
  },
  topHeaderPlaceholder: {
    minHeight: "4.5rem",

    [ScreenSizes.lgAndAbove]: {
      minHeight: "7.5rem",
      position: "relative",
      width: "100vw",
    },

    [createBreakpoint({ min: 1400 })]: {
      minHeight: "4.5rem",
    },
  },
  topHeaderPlaceholderTransparent: {
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      minHeight: 0,
    },
    [createBreakpoint({ min: 1400 })]: {
      minHeight: 0,
    },
  },
  cookieContainer: {
    paddingLeft: "1.5rem",
    paddingBottom: "2.5rem",
    background: "white",
    zIndex: 99999,
    position: "relative",
  },
};

export const LoadableContext = createContext({
  loadableChange: () => null,
});

function TopLevelRoute(props) {
  const {
    asyncComponent,
    children,
    headerContentContainerBackgroundColor,
    headerNavWrapperStyles,
    height,
    isHomepage,
    loadingStyles,
    modules,
    onMenuToggleClick,
    path,
    routeComponent,
    searchBarBackgroundColor,
    transparent,
    withFooter,
    withHeader,
    withNavMenu,
    ...rest
  } = props;

  const { styles, css } = useStyles(baseStyles, props);

  const requestContext = useContext(RequestContext);

  const [hasDoneLoadable, setHasDoneLoadable] = useState(
    !!requestContext.server
  );

  const { allModulesLoaded } = useAddModules({ modules, path });

  const loadableChange = useCallback(
    (up) => {
      if (!hasDoneLoadable) {
        setHasDoneLoadable(true);
      }
    },
    [hasDoneLoadable]
  );

  const loadableContext = useMemo(
    () => ({
      loadableChange,
    }),
    [loadableChange]
  );

  const RouteComponent = routeComponent || Route;

  return (
    <RouteComponent path={path} {...rest}>
      <ErrorBoundary>
        <ChunkLoadErrorBoundary>
          <div className={css(styles.top__wrapper)}>
            <div
              className={css(
                styles.topHeaderPlaceholder,
                transparent && styles.topHeaderPlaceholderTransparent
              )}
            >
              <div className={css(styles.topWrapperHeader)}>
                <HeaderNavWrapper
                  withHeader={withHeader}
                  onMenuToggleClick={onMenuToggleClick}
                  transparent={transparent}
                  searchBarBackgroundColor={searchBarBackgroundColor}
                  headerContentContainerBackgroundColor={
                    headerContentContainerBackgroundColor
                  }
                  withNavMenu={withNavMenu}
                  styles={headerNavWrapperStyles}
                  isHomepage={isHomepage}
                />
              </div>
            </div>
            <div className={css(styles.topWrapperBody)}>
              <LoadableContext.Provider value={loadableContext}>
                <div className={css(styles.wrapper__body)}>
                  <div className={css(styles.wrapper__content)}>
                    {allModulesLoaded ? (
                      children
                    ) : (
                      <>
                        {asyncComponent ? (
                          asyncComponent
                        ) : (
                          <LoadingOverlay
                            key="topLevelRouteLoadingOverlay"
                            withPageHeader
                            height={height}
                            styles={loadingStyles}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </LoadableContext.Provider>
              {withFooter && <Footer />}
            </div>
          </div>
        </ChunkLoadErrorBoundary>
      </ErrorBoundary>
    </RouteComponent>
  );
}

TopLevelRoute.propTypes = {
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool,
  transparent: PropTypes.bool,
  modules: PropTypes.array,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.element,
  ]),
  routeComponent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.element,
  ]),
  searchBarBackgroundColor: PropTypes.string,
  headerContentContainerBackgroundColor: PropTypes.string,
  onMenuToggleClick: PropTypes.func,
  withNavMenu: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  headerNavWrapperStyles: PropTypes.object,
  loadingStyles: PropTypes.object,
  isHomepage: PropTypes.bool,
};

TopLevelRoute.defaultProps = {
  withFooter: true,
  withHeader: true,
  transparent: true,
  modules: [],
  path: null,
  component: null,
  routeComponent: null,
  searchBarBackgroundColor: null,
  headerContentContainerBackgroundColor: null,
  onMenuToggleClick: null,
  withNavMenu: true,
};

export default memo(TopLevelRoute);
