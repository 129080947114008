import { faClock } from "@fortawesome/free-regular-svg-icons/faClock";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { format } from "date-fns/format";
import { getDate } from "date-fns/getDate";
import { getDaysInMonth } from "date-fns/getDaysInMonth";
import { getMonth } from "date-fns/getMonth";
import { getYear } from "date-fns/getYear";
import { isAfter } from "date-fns/isAfter";
import { isEqual } from "date-fns/isEqual";
import { subYears } from "date-fns/subYears";
import range from "lodash/range";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import BasicSelect from "components/Common/FormElements/BasicSelect";

import { capitalize, isEmpty } from "utils/misc";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import fieldStyles from "styles/FieldStyles";
import getHoverQuery from "styles/getHoverQuery";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ...fieldStyles,
  inputField: {
    ...fieldStyles.inputField,
    display: "flex",
    flexDirection: "row",
    position: "relative",
    alignItems: "center",
    padding: "0 1rem",
    width: "100%",
    maxWidth: "18.75rem",
  },

  clockIcon: {
    fontSize: "1rem",
    paddingRight: "1rem",
  },
  clockIconDisabled: {
    opacity: 0.4,
  },
  select: {
    WebkitAppearance: "none",
    border: "none",
    padding: ".9rem .6rem .7rem",
    minWidth: "2.8rem",
    cursor: "pointer",
    outline: "none",
  },
  monthInput: {
    width: "98px",
  },
  selectDisabled: {
    cursor: "auto",
    opacity: 0.8,

    ":hover": {
      cursor: "not-allowed",
    },
  },
  dropdownContainer: {
    position: "relative",
  },
  dropdownArrowContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    left: ".1rem",
    right: 0,
    bottom: 0,
    top: 0,
    pointerEvents: "none",
    opacity: 0,
    transition: "opacity 400ms",
  },
  dropdownArrowContainerShow: {
    opacity: 1,
  },
  dropdownArrow: {
    fontSize: ".8rem",
    color: "#000",
    textAlign: "center",
  },
  clearButtonContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    paddingRight: ".5rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    pointerEvents: "none",

    [ScreenSizes.mdAndAbove]: {
      paddingRight: "1rem",
    },
  },
  clearButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: "1.6rem",
    height: "1.6rem",
    border: "1px #DADADA solid",
    backgroundColor: "#FFF",
    cursor: "pointer",
    pointerEvents: "auto",
    color: colours.oldSecondary,
    transition: "background-color 0.3s, border 0.3s",

    ...getHoverQuery({
      backgroundColor: "#FDFDFD",
      border: `1px ${colours.oldSecondary} solid`,
    }),
    [ScreenSizes.mdAndAbove]: {
      width: "2rem",
      height: "2rem",
    },
  },
  clearButtonIcon: {
    fontSize: ".8rem",

    [ScreenSizes.mdAndAbove]: {
      fontSize: "1rem",
    },
  },
};

const getMonthLabel = (month) => {
  if (month === "none") {
    return "Month";
  }

  return format(new Date(0, month + 1, 0), "MMMM");
};
const getDayLabel = (day) => {
  if (day === "none") {
    return "Day";
  }

  return format(new Date(0, 0, day), "Do");
};
const getYearLabel = (year) => {
  if (year === "none") {
    return "Year";
  }

  return year;
};

const forceInRange = (value, minDate = null, maxDate = null) => {
  if (maxDate && isAfter(value, maxDate)) {
    return maxDate;
  }

  if (minDate && isAfter(minDate, value)) {
    return minDate;
  }

  return value;
};

const calculateRanges = ({ value = new Date(), minDate, maxDate }) => {
  const ranges = {
    startYear: getYear(minDate),
    endYear: getYear(maxDate),
    startMonth: 0,
    endMonth: 11,
    startDay: 1,
    endDay: 31,
  };

  if (getYear(value) === getYear(minDate)) {
    ranges.startMonth = getMonth(minDate);

    if (getMonth(value) === getMonth(minDate)) {
      ranges.startDay = getDate(minDate);
    }
  } else if (getYear(value) === getYear(maxDate)) {
    ranges.endMonth = getMonth(maxDate);

    if (getMonth(value) === getMonth(maxDate)) {
      ranges.endDay = getDate(maxDate);
    }
  }

  const daysInMonth = getDaysInMonth(value);
  if (ranges.endDay > daysInMonth) {
    ranges.endDay = daysInMonth;
  }

  return ranges;
};

const DateDropdownSelect = (props) => {
  const {
    disabled,
    clearable,
    value: passedValue,
    form,
    name,
    onChange,
    defaultDate,
    minDate,
    maxDate,
    input,
    field,
    dropdownDistance,
    selected,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const initialValue = useRef(
    passedValue
      ? forceInRange(
          passedValue,
          minDate || subYears(new Date(), 10),
          maxDate || new Date()
        )
      : passedValue
  );

  const [value, setValue] = useState(initialValue.current);

  const [focused, setFocused] = useState(false);
  const [hoverName, setHoverName] = useState(null);
  const [ranges, setRanges] = useState(() =>
    calculateRanges({
      value: initialValue.current,
      minDate,
      maxDate,
    })
  );

  useEffect(() => {
    if (value !== passedValue) {
      setValue(passedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedValue]);
  // only update when the prop.value changes

  useEffect(() => {
    setRanges(calculateRanges({ maxDate, minDate, value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxDate, minDate]);

  const handleClear = useCallback(() => {
    if (form) {
      form.setFieldValue(name, null);
    } else if (onChange) {
      onChange(null);
    }

    setFocused(false);
    setValue(defaultDate);
  }, [name, form, onChange, defaultDate]);

  const propOnChange = useMemo(() => {
    if (onChange) {
      return onChange;
    }
    if (input) {
      return input.onChange;
    }
    if (field) {
      return field.onChange;
    }

    return null;
  }, [onChange, input, field]);

  const handleChange = useCallback(
    ({ year, month, day }) => {
      const baseValue = !value
        ? new Date(new Date().getFullYear(), 0, 1)
        : value;
      const newValue = forceInRange(
        new Date(
          year || getYear(baseValue),
          month === undefined ? getMonth(baseValue) : month,
          day === undefined ? getDate(baseValue) : day
        ),
        minDate,
        maxDate
      );

      const newValueFormatted = format(newValue, "yyyy-MM-dd");

      if (!isEqual(value, newValueFormatted)) {
        setValue(newValueFormatted);
        setRanges(calculateRanges({ maxDate, minDate, value: newValue }));

        const returnValue = `${getYear(newValue)}-${
          getMonth(newValue) + 1
        }-${getDate(newValue)}`;

        if (form) {
          if (form.setFieldValue) {
            form.setFieldValue(name, returnValue);
          }
          if (form.setFieldTouched) {
            form.setFieldTouched(name, returnValue);
          }
        } else if (propOnChange) {
          propOnChange(newValue);
        }
      }
    },
    [form, name, value, maxDate, minDate, propOnChange]
  );

  const renderClearButton = () => (
    <div className={css(styles.clearButtonContainer)}>
      <div className={css(styles.clearButton)} onClick={handleClear}>
        <span className={css(styles.clearButtonIcon)}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    </div>
  );

  const handleFieldChange = useCallback(
    (fieldName) => (newValue) => handleChange({ [fieldName]: newValue }),
    [handleChange]
  );
  const handleFieldFocus = useCallback(() => {
    setFocused(true);
    setHoverName(null);
  }, []);
  const handleFieldMouseEnter = useCallback(
    (fieldName) => () => {
      if (!disabled) {
        setHoverName(fieldName);
      }
    },
    [disabled]
  );
  const handleFieldMouseLeave = useCallback(() => setHoverName(null), []);
  const handleFieldBlur = useCallback(() => {
    setFocused(false);
    setHoverName(null);
  }, []);

  const renderSelect = (fieldName, getValue, getLabel, index) => {
    const capitalizedName = capitalize(fieldName);

    let options = range(
      ranges[`start${capitalizedName}`],
      ranges[`end${capitalizedName}`] + 1
    );

    if (fieldName === "year") {
      options = options.slice().reverse();
    }

    if (!value && !focused) {
      options = ["none", ...options];
    }

    return (
      <div
        className={css(styles.dropdownContainer)}
        style={{ left: `-${dropdownDistance * index * 0.1}rem` }}
      >
        <BasicSelect
          name={name}
          dataId={`date-${fieldName}-input`}
          options={options.map((val) => ({
            value: val,
            label: getLabel ? getLabel(val) : val,
          }))}
          value={isEmpty(value) ? "none" : getValue(value)}
          onChange={handleFieldChange(fieldName)}
          onFocus={handleFieldFocus}
          onMouseEnter={handleFieldMouseEnter(fieldName)}
          onMouseLeave={handleFieldMouseLeave}
          onBlur={handleFieldBlur}
          selectClassName={css(
            styles.select,
            disabled && styles.selectDisabled,
            fieldName === "month" && styles.monthInput
          )}
          disabled={disabled}
          dataTestId={`${name}-input`}
        />
        <div
          className={css(
            styles.dropdownArrowContainer,
            hoverName === fieldName && styles.dropdownArrowContainerShow
          )}
        >
          <span className={css(styles.dropdownArrow)}>
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={css(styles.inputField, selected && styles.selectedStyles)}>
      <span
        className={css(styles.clockIcon, disabled && styles.clockIconDisabled)}
      >
        <FontAwesomeIcon icon={faClock} />
      </span>
      {renderSelect(
        "month",
        (val) => (val === "none" ? "none" : getMonth(value)),
        getMonthLabel,
        1
      )}
      {renderSelect(
        "day",
        (val) => (val === "none" ? "none" : getDate(value)),
        getDayLabel,
        2
      )}
      {renderSelect(
        "year",
        (val) => (val === "none" ? "none" : getYear(value)),
        getYearLabel,
        3
      )}
      {clearable && passedValue && renderClearButton()}
    </div>
  );
};

DateDropdownSelect.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  defaultDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  input: PropTypes.object,
  field: PropTypes.object,
  form: PropTypes.object,
  clearable: PropTypes.bool,
  dropdownDistance: PropTypes.number,
};

DateDropdownSelect.defaultProps = {
  name: null,
  onChange: null,
  minDate: null,
  maxDate: null,
  value: null,
  disabled: false,
  input: null,
  field: null,
  form: null,
  defaultDate: new Date(),
  clearable: false,
  dropdownDistance: 8,
};

export default memo(DateDropdownSelect);
