import { commonModuleGroup, podcastModuleGroup } from "modules/groups";
import { Redirect, Switch } from "react-router-dom";
import { useLocation } from "react-router-dom";

import RegisteredRoute from "components/Common/RegisteredRoute";
import RegistrationRequired from "pages/Auth/RegistrationRequired";
import TopLevelRoute from "pages/Common/TopLevelRoute";

import Networks from "../Discover/Networks/async";
import NetworkViewContainer from "./async";

function NetworkRoutes() {
  const { search } = useLocation();

  const isNewNetwork = search.includes("newNetworkView");

  return (
    <Switch>
      <TopLevelRoute
        path="/network"
        exact
        transparent={false}
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <Networks />
      </TopLevelRoute>
      <TopLevelRoute
        path="/networks"
        exact
        transparent={false}
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <Networks />
      </TopLevelRoute>
      <TopLevelRoute
        path="/network(s?)/:sort(alpha|podcastCount)"
        exact
        transparent={false}
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <Networks />
      </TopLevelRoute>
      <TopLevelRoute
        path="/network(s?)/:id"
        transparent={!isNewNetwork}
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
        routeComponent={RegisteredRoute}
        notRegisteredComponent={RegistrationRequired}
        notAuthorizedComponent={() => <Redirect to={"/"} />}
      >
        <NetworkViewContainer isNewNetwork={isNewNetwork} />
      </TopLevelRoute>
    </Switch>
  );
}

export default NetworkRoutes;
